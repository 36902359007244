<template>
    <div class="card">
        <div class="card-header">{{ $t('crud.payment_methods._create') }}</div>
        <div class="card-body">
            <payment-methods-form-component @submit="create" :record="record" :loading="loading"></payment-methods-form-component>
        </div>
    </div>
</template>

<script>
    import {functions, config} from '../crud/mixin_config';
    import PaymentMethodsFormComponent from "./Form";

    export default {
        mixins: [functions],
        components: {PaymentMethodsFormComponent},
        data() {
            return {
                crud: config('payment_methods'),
                record: {
                    // Empty model.
                }
            };
        }
    }
</script>
