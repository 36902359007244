<template>
    <form @submit.prevent="submit()">
        <b-form-group :label="$t('crud.payment_methods.name')">
            <b-form-input v-model="record.name" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.payment_methods.description')">
            <b-form-textarea v-model="record.description" rows="4"></b-form-textarea>
        </b-form-group>
        <b-form-group :label="$t('crud.payment_methods.order_paid_content')">
            <editor v-model="record.order_paid_content"></editor>
        </b-form-group>
        <b-form-group :label="$t('crud.payment_methods.order_not_paid_content')">
            <editor v-model="record.order_not_paid_content"></editor>
        </b-form-group>
        <b-form-group :label="$t('crud.payment_methods.handler_class')">
            <b-form-select v-model="record.handler_class" :options="handler_classes"></b-form-select>
        </b-form-group>
        <div class="row">
            <div v-for="(day, index) in days" class="col">
                <b-form-group :label="day.ucfirst()" class="mb-0">
                    <b-form-input v-model="record['day_' + index + '_processing_time']" required></b-form-input>
                </b-form-group>
            </div>
        </div>
        <p class="mb-2"><small class="text-muted">{{ $t('crud.payment_methods.days_description') }}</small></p>
        <b-form-group :label="$t('crud.payment_methods.daily_closing_time')" :description="$t('crud.payment_methods.daily_closing_time_description')">
            <b-form-input v-model="record.daily_closing_time" required></b-form-input>
        </b-form-group>
        <loading-button class="btn btn-primary" type="submit" :loading="loading">{{ $t('crud.save') }}</loading-button>
    </form>
</template>

<script>
    export default {
        name: 'pickup-locations-form-component',
        props: ['record', 'loading'],
        mounted() {
            this.days = this.$moment.weekdays();
        },
        data() {
            return {
                days: [],
                handler_classes: [
                    'App\\PaymentSystem\\BankTransfer',
                    'App\\PaymentSystem\\CashPayment',
                    'App\\PaymentSystem\\Mollie',
                    'App\\PaymentSystem\\Sofort',
                ]
            }
        },
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>
